import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import validate from './plugins/validate.js'
import { ValidationObserver } from 'vee-validate'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import VueCurrencyFilter from 'vue-currency-filter'
import VueNumericInput from 'vue-numeric-input'
import VueNumeric from 'vue-numeric'

import moment from 'moment'
require('moment/locale/id')

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import wb from './registerServiceWorker'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

import Permissions from '@core/mixins/Permission'
Vue.mixin(Permissions)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(require('vue-moment'), { moment })

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(VueNumericInput)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.component('DatePicker', DatePicker)
Vue.component('ValidationProvider', validate)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('VueNumeric', VueNumeric)

Vue.use(VueCurrencyFilter, [
  {
    name: 'numFormat',
    symbol: '',
    thousandsSeparator: '.',
    fractionCount: 0,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true,
  },
  {
    symbol: 'Rp',
    thousandsSeparator: '.',
    fractionCount: 0,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true,
  },
])
Vue.config.productionTip = false

Vue.prototype.$workbox = wb

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
