import { ValidationProvider, extend } from 'vee-validate'
import {
  required,
  max,
  max_value,
  min_value,
  email,
  min,
  digits,
  confirmed,
  numeric,
  regex,
} from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: '{_field_} harus diisi',
})

extend('min', {
  ...min,
  params: ['length'],
  message: '{_field_} ini minimal harus {length} karakter',
})

extend('max', {
  ...max,
  params: ['length'],
  message: '{_field_} ini maksimal harus {length} karakter',
})

extend('digits', {
  ...digits,
  message: '{_field_} ini harus {length} angka',
})

extend('max_value', {
  ...max_value,
  message: 'The {_field_} field must be {max} or less',
})

extend('min_value', {
  ...min_value,
  message: 'Masukkan {_field_} minimal {min} atau lebih',
})

extend('email', {
  ...email,
  message: '{_field_} yang di masukkan tidak sesuai',
})

extend('confirmed', {
  ...confirmed,
  message: 'Konfirmasi password tidak sesuai',
})

extend('numeric', {
  ...numeric,
  message: '{_field_} yang di masukkan tidak sesuai',
})

extend('regex', {
  ...regex,
  message: '{_field_} yang di masukkan tidak sesuai',
})

export default ValidationProvider
