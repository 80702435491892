export default [
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  },
  {
    path: '/dashboard/ecommerce',
    name: 'dashboard-ecommerce',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      permissionlink: 'read/home',
    },
    component: () => import('@/views/dashboard/Home.vue'),
  },

  {
    path: '/manajemen-pegawai',
    name: 'manajemen-pegawai',
    meta: {
      title: 'Manajemen Pegawai',
      permissionlink: 'read/manajemen-pegawai',
    },
    component: () => import('@/views/manajemen-pegawai/List.vue'),
  },
  {
    path: '/manajemen-pegawai/:id/detail',
    name: 'manajemen-pegawai',
    meta: {
      title: 'Detail Pegawai',
      permissionlink: 'show/manajemen-pegawai',
    },
    component: () => import('@/views/manajemen-pegawai/Detail.vue'),
  },

  {
    path: '/manajemen-nonpegawai',
    name: 'manajemen-nonpegawai',
    meta: {
      title: 'Manajemen Non Pegawai',
      permissionlink: 'read/manajemen-nonpegawai',
    },
    component: () => import('@/views/manajemen-nonpegawai/List.vue'),
  },
  {
    path: '/manajemen-nonpegawai/:id/detail',
    name: 'manajemen-nonpegawai',
    meta: {
      title: 'Detail Non Pegawai',
      permissionlink: 'show/manajemen-nonpegawai',
    },
    component: () => import('@/views/manajemen-nonpegawai/Detail.vue'),
  },
  {
    path: '/manajemen-nonpegawai/:id/edit',
    name: 'manajemen-nonpegawai',
    meta: {
      title: 'Ubah Non Pegawai',
      action: 'edit',
      permissionlink: 'edit/manajemen-nonpegawai',
    },
    component: () => import('@/views/manajemen-nonpegawai/Form.vue'),
  },
  {
    path: '/manajemen-nonpegawai/add',
    name: 'manajemen-nonpegawai',
    meta: {
      title: 'Tambah Non Pegawai',
      action: 'store',
      permissionlink: 'store/manajemen-nonpegawai',
    },
    component: () => import('@/views/manajemen-nonpegawai/Form.vue'),
  },

  {
    path: '/manajemen-role',
    name: 'manajemen-role',
    meta: {
      title: 'Manajemen Role',
      permissionlink: 'read/manajemen-role',
    },
    component: () => import('@/views/manajemen-role/List.vue'),
  },
  {
    path: '/manajemen-role/:id/edit',
    name: 'manajemen-role',
    meta: {
      title: 'Ubah Role',
      action: 'edit',
      permissionlink: 'edit/manajemen-role',
    },
    component: () => import('@/views/manajemen-role/Form.vue'),
  },
  {
    path: '/manajemen-role/add',
    name: 'manajemen-role',
    meta: {
      title: 'Tambah Role',
      action: 'store',
      permissionlink: 'store/manajemen-role',
    },
    component: () => import('@/views/manajemen-role/Form.vue'),
  },
  {
    path: '/manajemen-role/:id/permission',
    name: 'manajemen-role',
    meta: {
      title: 'Assign Permission Role',
      action: 'store',
      permissionlink: 'assign_permission/manajemen-role',
    },
    component: () => import('@/views/manajemen-role/AssignRolePermission.vue'),
  },
  {
    path: '/manajemen-role/:id/position',
    name: 'manajemen-role',
    meta: {
      title: 'Assign NIPPOS Role',
      action: 'store',
      permissionlink: 'assign_position/manajemen-role',
    },
    component: () => import('@/views/manajemen-role/AssignRolePosition.vue'),
  },

  {
    path: '/komponen-biaya',
    name: 'komponen-biaya',
    meta: {
      title: 'Manajemen Komponen Biaya',
      permissionlink: 'read/komponen-biaya',
    },
    component: () => import('@/views/master-data/komponen-biaya/List.vue'),
  },
  {
    path: '/komponen-biaya/:id/edit',
    name: 'komponen-biaya',
    meta: {
      title: 'Ubah Komponen Biaya',
      action: 'edit',
      permissionlink: 'edit/komponen-biaya',
    },
    component: () => import('@/views/master-data/komponen-biaya/Form.vue'),
  },
  {
    path: '/komponen-biaya/add',
    name: 'komponen-biaya',
    meta: {
      title: 'Tambah Komponen Biaya',
      action: 'store',
      permissionlink: 'store/komponen-biaya',
    },
    component: () => import('@/views/master-data/komponen-biaya/Form.vue'),
  },

  {
    path: '/alat-transportasi',
    name: 'alat-transportasi',
    meta: {
      title: 'Manajemen Alat Transportasi',
      permissionlink: 'read/alat-transportasi',
    },
    component: () => import('@/views/master-data/alat-transportasi/List.vue'),
  },
  {
    path: '/alat-transportasi/:id/edit',
    name: 'alat-transportasi',
    meta: {
      title: 'Ubah Alat Transportasi',
      action: 'edit',
      permissionlink: 'edit/alat-transportasi',
    },
    component: () => import('@/views/master-data/alat-transportasi/Form.vue'),
  },
  {
    path: '/alat-transportasi/add',
    name: 'alat-transportasi',
    meta: {
      title: 'Tambah Alat Transportasi',
      action: 'store',
      permissionlink: 'store/alat-transportasi',
    },
    component: () => import('@/views/master-data/alat-transportasi/Form.vue'),
  },

  {
    path: '/mitra',
    name: 'mitra',
    meta: {
      title: 'Manajemen Mitra',
      permissionlink: 'read/mitra',
    },
    component: () => import('@/views/master-data/mitra/List.vue'),
  },
  {
    path: '/mitra/:id/edit',
    name: 'mitra',
    meta: {
      title: 'Ubah Mitra',
      action: 'edit',
      permissionlink: 'edit/mitra',
    },
    component: () => import('@/views/master-data/mitra/Form.vue'),
  },
  {
    path: '/mitra/add',
    name: 'mitra',
    meta: {
      title: 'Tambah Mitra',
      action: 'store',
      permissionlink: 'store/mitra',
    },
    component: () => import('@/views/master-data/mitra/Form.vue'),
  },

  {
    path: '/uang-harian',
    name: 'uang-harian',
    meta: {
      title: 'Manajemen Uang Harian',
      permissionlink: 'read/uang-harian',
    },
    component: () => import('@/views/master-data/uang-harian/List.vue'),
  },
  {
    path: '/uang-harian/:id/edit',
    name: 'uang-harian',
    meta: {
      title: 'Ubah Uang Harian',
      action: 'edit',
      permissionlink: 'edit/uang-harian',
    },
    component: () => import('@/views/master-data/uang-harian/Form.vue'),
  },
  {
    path: '/uang-harian/add',
    name: 'uang-harian',
    meta: {
      title: 'Tambah Uang Harian',
      action: 'store',
      permissionlink: 'store/uang-harian',
    },
    component: () => import('@/views/master-data/uang-harian/Form.vue'),
  },

  {
    path: '/pph21',
    name: 'pph21',
    meta: {
      title: 'Manajemen PPH21',
      permissionlink: 'read/pph21',
    },
    component: () => import('@/views/master-data/pph21/List.vue'),
  },
  {
    path: '/pph21/:id/edit',
    name: 'pph21',
    meta: {
      title: 'Ubah Manajemen PPH21',
      action: 'edit',
      permissionlink: 'edit/pph21',
    },
    component: () => import('@/views/master-data/pph21/Form.vue'),
  },
  {
    path: '/pph21/add',
    name: 'pph21',
    meta: {
      title: 'Tambah Manajemen PPH21',
      action: 'store',
      permissionlink: 'store/pph21',
    },
    component: () => import('@/views/master-data/pph21/Form.vue'),
  },

  {
    path: '/manajemen-sppd',
    name: 'manajemen-sppd',
    meta: {
      title: 'Kelola SPPD',
      permissionlink: 'read/manajemen-sppd',
    },
    component: () => import('@/views/manajemen-sppd/List.vue'),
  },
  {
    path: '/manajemen-sppd/:id/detail',
    name: 'manajemen-sppd',
    meta: {
      title: 'Detail SPPD',
      permissionlink: 'show/manajemen-sppd',
    },
    component: () => import('@/views/manajemen-sppd/detail/Detail.vue'),
  },
  {
    path: '/manajemen-sppd/:id/edit',
    name: 'manajemen-sppd',
    meta: {
      title: 'Ubah SPPD',
      action: 'edit',
      permissionlink: 'edit/manajemen-sppd',
    },
    component: () => import('@/views/manajemen-sppd/Form.vue'),
  },
  {
    path: '/manajemen-sppd/add',
    name: 'manajemen-sppd',
    meta: {
      title: 'Tambah SPPD',
      action: 'store',
      permissionlink: 'store/manajemen-sppd',
    },
    component: () => import('@/views/manajemen-sppd/Form.vue'),
  },

  // Upload Bukti Pengeluaran - Pengeluaran Riil
  {
    path: '/pengeluaran-rills',
    name: 'pengeluaran-rills',
    meta: {
      title: 'Upload Pengeluaran Riil',
      permissionlink: 'read/pengeluaran-rills',
    },
    component: () =>
      import('@/views/bukti-pengeluaran/pengeluaran-riil/List.vue'),
  },
  {
    path: '/pengeluaran-rills/:sppdId',
    name: 'pengeluaran-rills',
    meta: {
      title: 'Detail Pengeluaran Riil',
      permissionlink: 'detail/pengeluaran-rills',
    },
    component: () =>
      import('@/views/bukti-pengeluaran/pengeluaran-riil/Detail.vue'),
  },
  {
    path: '/pengeluaran-riils/:sppdId/add',
    name: 'add-pengeluaran-riils',
    meta: {
      title: 'Tambah Pengeluaran Riil',
      action: 'store',
      permissionlink: 'store/pengeluaran-rill',
    },
    component: () =>
      import('@/views/bukti-pengeluaran/pengeluaran-riil/Form.vue'),
  },
  {
    path: '/pengeluaran-riils/:sppdId/detail/:sppdPengeluaranRiilId/edit',
    name: 'edit-pengeluaran-riils',
    meta: {
      title: 'Edit Pengeluaran Riil',
      action: 'edit',
      permissionlink: 'edit/pengeluaran-rill',
    },
    component: () =>
      import('@/views/bukti-pengeluaran/pengeluaran-riil/Form.vue'),
  },

  // Upload Bukti Pengeluaran - Biaya Transportasi
  {
    path: '/biaya-transportasi',
    name: 'biaya-transportasi',
    meta: {
      title: 'Upload Biaya Transportasi',
      permissionlink: 'read/biaya-transportasi',
    },
    component: () =>
      import('@/views/bukti-pengeluaran/biaya-transportasi/List.vue'),
  },
  {
    path: '/biaya-transportasi/:sppdId',
    name: 'biaya-transportasi',
    meta: {
      title: 'Detail Biaya Transportasi',
      permissionlink: 'detail/biaya-transportasi',
    },
    component: () =>
      import('@/views/bukti-pengeluaran/biaya-transportasi/Detail.vue'),
  },
  {
    path: '/biaya-transportasi/:sppdId/add',
    name: 'add-transportation',
    meta: {
      title: 'Tambah Biaya Transportasi Dalam Negeri',
      action: 'store',
      permissionlink: 'store/alat-transportasi',
    },
    component: () =>
      import('@/views/bukti-pengeluaran/biaya-transportasi/Form.vue'),
  },
  {
    path: '/manajemen-sppd/:sppdId/detail/:sppdTransportationId/edit',
    name: 'edit-transportation',
    meta: {
      title: 'Edit Biaya Transportasi Dalam Negeri',
      action: 'edit',
      permissionlink: 'edit/alat-transportasi',
    },
    component: () =>
      import('@/views/bukti-pengeluaran/biaya-transportasi/Form.vue'),
  },

  // Upload Bukti Pengeluaran - Biaya Akomodasi
  {
    path: '/biaya-akomodasi',
    name: 'biaya-akomodasi',
    meta: {
      title: 'Upload Biaya Akomodasi',
      permissionlink: 'read/biaya-akomodasi',
    },
    component: () =>
      import('@/views/bukti-pengeluaran/biaya-akomodasi/List.vue'),
  },
  {
    path: '/biaya-akomodasi/:sppdId',
    name: 'biaya-akomodasi',
    meta: {
      title: 'Detail Biaya Akomodasi',
      permissionlink: 'detail/biaya-akomodasi',
    },
    component: () =>
      import('@/views/bukti-pengeluaran/biaya-akomodasi/Detail.vue'),
  },
  {
    path: '/biaya-akomodasi/:sppdId/add',
    name: 'add-accommodation',
    meta: {
      title: 'Tambah Biaya Akomodasi Dalam Negeri',
      action: 'store',
      permissionlink: 'store/alat-akomodasi',
    },
    component: () =>
      import('@/views/bukti-pengeluaran/biaya-akomodasi/Form.vue'),
  },
  {
    path: '/manajemen-sppd/:sppdId/detail/:sppdAccommodationId/edit',
    name: 'edit-accommodation',
    meta: {
      title: 'Edit Biaya Akomodasi Dalam Negeri',
      action: 'edit',
      permissionlink: 'edit/alat-akomodasi',
    },
    component: () =>
      import('@/views/bukti-pengeluaran/biaya-akomodasi/Form.vue'),
  },

  // Manajemen SPPD - Biaya Pemeriksaan
  {
    path: '/manajemen-sppd/:sppdId/inspection/add',
    name: 'add-inspection',
    meta: {
      title: 'Tambah Biaya Pemeriksaan',
      action: 'store',
      permissionlink: '',
    },
    component: () =>
      import('@/views/manajemen-sppd/detail/pemeriksaan/Form.vue'),
  },
  {
    path: '/manajemen-sppd/:sppdId/inspection/:sppdInspectionId/edit',
    name: 'edit-inspection',
    meta: {
      title: 'Edit Biaya Pemeriksaan',
      action: 'edit',
      permissionlink: '',
    },
    component: () =>
      import('@/views/manajemen-sppd/detail/pemeriksaan/Form.vue'),
  },

  // Manajemen SPPD - Biaya Sumbangan Pindah
  {
    path: '/manajemen-sppd/:sppdId/donation/add',
    name: 'add-donation',
    meta: {
      title: 'Tambah Biaya Sumbangan Pindah',
      action: 'store',
      permissionlink: '',
    },
    component: () =>
      import('@/views/manajemen-sppd/detail/sumbangan-pindah/Form.vue'),
  },
  {
    path: '/manajemen-sppd/:sppdId/donation/:sppdDonationId/edit',
    name: 'edit-donation',
    meta: {
      title: 'Edit Biaya Sumbangan Pindah',
      action: 'edit',
      permissionlink: '',
    },
    component: () =>
      import('@/views/manajemen-sppd/detail/sumbangan-pindah/Form.vue'),
  },

  {
    path: '/pengeluaran-rill',
    name: 'pengeluaran-rill',
    meta: {
      title: 'Pengeluaran Rill',
      permissionlink: 'read/pengeluaran-rill',
    },
    component: () => import('@/views/pengeluaran-rill/List.vue'),
  },
  {
    path: '/pengeluaran-rill/:id/detail',
    name: 'pengeluaran-rill',
    meta: {
      title: 'Detail Pengeluaran Rill',
      permissionlink: 'show/pengeluaran-rill',
    },
    component: () => import('@/views/pengeluaran-rill/Detail.vue'),
  },
  {
    path: '/pengeluaran-rill/:id/edit',
    name: 'pengeluaran-rill',
    meta: {
      title: 'Ubah Pengeluaran Rill',
      action: 'edit',
      permissionlink: 'edit/pengeluaran-rill',
    },
    component: () => import('@/views/pengeluaran-rill/Edit.vue'),
  },
  {
    path: '/pengeluaran-rill/add',
    name: 'pengeluaran-rill',
    meta: {
      title: 'Tambah Pengeluaran Rill',
      action: 'store',
      permissionlink: 'store/pengeluaran-rill',
    },
    component: () => import('@/views/pengeluaran-rill/Add.vue'),
  },

  {
    path: '/bukti-bayar',
    name: 'bukti-bayar',
    meta: {
      title: 'Bukti Pembayaran',
      permissionlink: 'read/bukti-bayar',
    },
    component: () => import('@/views/bukti-bayar/List.vue'),
  },
  {
    path: '/bukti-bayar/:id/detail',
    name: 'bukti-bayar',
    meta: {
      title: 'Detail Bukti Pembayaran',
      permissionlink: 'show/bukti-bayar',
    },
    component: () => import('@/views/bukti-bayar/Detail.vue'),
  },
  {
    path: '/bukti-bayar/:id/edit',
    name: 'bukti-bayar',
    meta: {
      title: 'Ubah Bukti Pembayaran',
      action: 'edit',
      permissionlink: 'edit/bukti-bayar',
    },
    component: () => import('@/views/bukti-bayar/Edit.vue'),
  },
  {
    path: '/bukti-bayar/add',
    name: 'bukti-bayar',
    meta: {
      title: 'Tambah Bukti Pembayaran',
      action: 'store',
      permissionlink: 'store/bukti-bayar',
    },
    component: () => import('@/views/bukti-bayar/Form.vue'),
  },

  {
    path: '/laporan-sppd',
    name: 'laporan-sppd',
    meta: {
      title: 'Laporan SPPD',
      permissionlink: 'read/laporan-sppd',
    },
    component: () => import('@/views/laporan-sppd/List.vue'),
  },
  {
    path: '/laporan-uang-harian',
    name: 'laporan-uang-harian',
    meta: {
      title: 'Laporan Uang Harian',
      permissionlink: 'read/laporan-uang-harian',
    },
    component: () => import('@/views/laporan-uang-harian/List.vue'),
  },

  // Manajemen NPP
  {
    path: '/manajemen-npp',
    name: 'manajemen-npp',
    meta: {
      title: 'Manajemen NPP',
      permissionlink: 'read/manajemen-npp',
    },
    component: () => import('@/views/manajemen-npp/List.vue'),
  },
  {
    path: '/manajemen-npp/generate',
    name: 'manajemen-npp-generate',
    meta: {
      title: 'Manajemen NPP - Generate',
      permissionlink: 'store/manajemen-npp',
    },
    component: () => import('@/views/manajemen-npp/Generate.vue'),
  },
  {
    path: '/manajemen-npp/:id',
    name: 'manajemen-npp-detail',
    meta: {
      title: 'Manajemen NPP - Detail',
      permissionlink: 'show/manajemen-npp',
    },
    component: () => import('@/views/manajemen-npp/Detail.vue'),
  },
  {
    path: '/pengajuan-npp',
    name: 'pengajuan-npp',
    meta: {
      title: 'Pengajuan NPP',
      permissionlink: 'read/pengajuan-npp',
    },
    component: () =>
      import('@/views/pengajuan-npp/list/data-pengajuan/List.vue'),
  },
  {
    path: '/pengajuan-npp/generated',
    name: 'pengajuan-npp-generated',
    meta: {
      title: 'Pengajuan NPP',
      permissionlink: 'read/pengajuan-npp',
    },
    component: () =>
      import('@/views/pengajuan-npp/list/generated-pengajuan/List.vue'),
  },
  {
    path: '/pengajuan-npp/preview',
    name: 'pengajuan-npp-preview',
    meta: {
      title: 'Pengajuan NPP',
      permissionlink: 'read/pengajuan-npp',
    },
    component: () => import('@/views/pengajuan-npp/Preview.vue'),
  },
  {
    path: '/pengajuan-npp/:id',
    name: 'pengajuan-npp-detail',
    meta: {
      title: 'Pengajuan NPP - Detail',
      permissionlink: 'show/pengajuan-npp',
    },
    component: () => import('@/views/pengajuan-npp/Detail.vue'),
  },
]
