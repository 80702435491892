export default {
  methods: {
    can(permissionName) {
      const permissions = window.localStorage.getItem('all_permission')
      if (typeof permissions != 'undefined') {
        return permissions?.indexOf(permissionName) > -1
      }
    },
  },
}
